<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        {{ blog.name }}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.blogs.index'}">Back</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form ref="form">
                    <v-text-field label="Main image" disabled></v-text-field>
                    <v-row>
                        <v-col class="d-flex justify-center align-center flex-column">
                            <div class="min-width100">
                                <avatar-edit min-width="100%" :current_url="blog.main_img_url" @input="handleSaveBlog"
                                    v-model="blog.main_img_url"></avatar-edit>
                            </div>
                            <div class="min-width100">
                                <v-text-field label="Image URL" v-model="blog.main_img_url" disabled></v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                    <v-btn style="border: 1px solid grey;" text @click.prevent="addTag">Add tag</v-btn>
                    <div v-for="(tag, index) in tags" :key="index">
                        <v-row>
                            <v-col>
                                <v-text-field :label="index + 1 +' tag in russian'" v-model="tag.text_ru"
                                    placeholder="Please enter a tag in russian" outlined required></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field :label="index + 1 + ' tag in english'" v-model="tag.text_en"
                                    placeholder="Please enter a tag in english" outlined required></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field :label="index + 1 + ' tag in armenian'" v-model="tag.text_hy"
                                    placeholder="Please enter a tag in armenian" outlined required></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                    <v-row>
                        <v-col>
                            <v-text-field label="Title in russian" placeholder="Please enter a title in russian"
                                outlined required v-model="blog.title_ru"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Title in english" placeholder="Please enter a title in english"
                                outlined required v-model="blog.title_en"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Title in armenian" placeholder="Please enter a title in armenian"
                                outlined required v-model="blog.title_hy"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea type="text" placeholder="Please enter text in russian"
                                label="Main text in russian" v-model="blog.text_ru"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea type="text" placeholder="Please enter text in english"
                                label="Main text in english" v-model="blog.text_en"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea type="text" placeholder="Please enter text in russian"
                                label="Main text in armenian" v-model="blog.text_hy"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-text-field label="Image to text" disabled></v-text-field>
                    <v-row>
                        <v-col class="d-flex justify-center align-center flex-column">
                            <div class="min-width100">
                                <avatar-edit min-width="100%" :current_url="blog.text_img_url" @input="handleSaveBlog"
                                    v-model="blog.text_img_url"></avatar-edit>
                            </div>
                            <div class="min-width100">
                                <v-text-field label="Image URL" v-model="blog.text_img_url" disabled></v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn block color="green" @click="handleSaveBlog">Save Changes</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
        <v-snackbar v-model="snackbar" color="green" :timeout="timeout_snackbar">{{ text_snackbar }}</v-snackbar>
    </admin-dashboard-layout>
</template>

<script>
import Blog from "../../../models/Blog";
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import AvatarEdit from "../../../components/AvatarEdit";
import BlogTag from "../../../models/BlogTag";

export default {
    name: "blogs-show",
    components: { AdminDashboardLayout, AvatarEdit },
    data: function () {
        return {
            formatDate: 0,
            flagSaveUpdate: false,
            blog: {},
            formValid: true,
            tags: [],
            nameRules: [v => !!v || 'Name is required'],
            snackbar: false,
            text_snackbar: 'Saved successfully',
            timeout_snackbar: 2000,

        }
    },
    methods: {
        async handleSaveBlog() {
            this.formValid = this.$refs.form.validate()
            if (this.formValid) {

                const blog = await new Blog(this.blog);

                this.blog = this.flagSaveUpdate ? await blog.patch() : await blog.save()

                for (let i = 0; i < this.tags.length; i++) {
                    this.tags[i].blog_id = this.blog.id

                    const tag = await new BlogTag(this.tags[i])

                    this.tags[i] = this.flagSaveUpdate ? await tag.patch() : await tag.save()
                }

                await this.$router.push({ name: 'admin.blogs.show', params: { id: this.blog.id } }).catch(() => { })

                this.snackbar = true
            }
        },
        returnFormDate(uc) {
            this.formatDate = new Date(uc).toLocaleString()
        },
        addTag: function () {
            this.tags.push({ text_ru: '', text_en: '', text_hy: '' })
        }
    },
    async mounted() {
        const blogId = this.$route.params.id
        if (blogId) {
            this.blog = await Blog.find(blogId)
            this.tags = await BlogTag.custom('/blogTags/id/' + blogId).get()
            this.flagSaveUpdate = true
            this.returnFormDate(this.blog.created_at)
        }
    },
}
</script>

<style scoped>

</style>
